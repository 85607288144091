import car from "../../Assets/icons/car.svg";
import call from "../../Assets/icons/call.svg";
import clock from "../../Assets/icons/clock.svg";
import { useEffect, useState } from "react";

import { MapContainer, TileLayer, Marker } from "react-leaflet";
import Routing from "../../Components/Routing";
import {
  getDriveDetails,
  getDriverLocation,
  getEtaData,
} from "../../Services/getServices";

type Props = {
  pickLocation: any;
  dropLocation: any;
  driverDetails: any;
  carDetails: any;
};
// const dummy =
//   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1x_1ueYyzVI5MQ8SZMG6iKA7w-EpP_Q6riA&usqp=CAU";

const LoactionMap = ({
  pickLocation,
  dropLocation,
  driverDetails,
  carDetails,
}: Props) => {
  const [driverLocation, setDriverLocation] = useState<number[]>([]);
  const [rideStatus, setRideStatus] = useState<string>("pending");
  // const [eta, setEta] = useState<string>("00.00 Minutes");
  const [newEta, setNewEta] = useState<string>("00.00 mins");

  const id =
    window.location.href.split("/").at(-1) || `62d521c28f59d6f43458fc96`;
  // const id =
  //   window.location.href.split("/").at(-1) || `62f4ed958c8a3396489032a4`;

  const getdriverlocation = async () => {
    try {
      console.log(driverDetails?._id);

      const driver = await getDriverLocation(driverDetails?._id);
      console.log(driver?.data?.data?.hotel, "driver");

      getEta(driver?.data?.data?.driver?._id, driver?.data?.data?.hotel); //to fetch the ETA

      console.log(
        driver.data.data.location.coordinates,
        "my testing data for driver location"
      );
      console.log(driver?.data?.data?.location?.coordinates);
      setDriverLocation(driver?.data?.data?.location?.coordinates)

      // code to randomly generate coordinates and access previous value in state
      // setDriverLocation(value => {
      //   console.log("VALUVLAUE", value)
      //   if (value.length) {
      //     return ([value?.[0] + 1, value?.[1] + 1])
      //   }
      //   else {
      //     return driver?.data?.data?.location?.coordinates
      //   }
      // });

    } catch (err) {
      console.log(err);
    }
  };

  const getbookingstatus = async () => {
    try {
      const { data } = await getDriveDetails(id);
      setRideStatus(data.data.status);
      console.log(
        data.data.status,
        "this is driver detail from locationn page"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getEta = async (driver_id: string, hotel_id: string) => {
    try {
      const { data } = await getEtaData(driver_id, hotel_id);
      setNewEta(data.data[0]);
      console.log("this is New ETA", data.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable */

  useEffect(() => {
    getdriverlocation();
    getbookingstatus();
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      getdriverlocation();
      getbookingstatus();
      // getEta();
    }, 5000);
    return () => clearInterval(myInterval);
  }, []);
  /* eslint-enable */

  // console.log( pickLocation, dropLocation, driverDetails, carDetails,"driver location")
  return (
    <section>
      <header className="py-8 bg-[#1F1F1F]">
        <div className="flex items-center justify-center space-x-3">
          <img src={car} alt="locationMark" />
          <h1 className="text-2xl text-white">My Ride</h1>
        </div>
      </header>
      <section className="relative">
        <MapContainer
          center={[20.593683, 78.962883]}
          zoom={7}
          scrollWheelZoom={false}
          style={{
            height: "600px",
            zIndex: 10,
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {driverLocation.length !== 0 ? (
            <Routing
              pickLat={pickLocation?.[0]}
              pickLng={pickLocation?.[1]}
              dropLat={dropLocation?.[0]}
              dropLng={dropLocation?.[1]}
              // driverLocation={driverLocation}
              rideStatus={rideStatus}
              driverLocationLat={driverLocation[0]}
              driverLocationLong={driverLocation[1]}
            // setEta={setEta}
            />
          ) : (
            ""
          )}
          {/* <Marker position={[34.182049, -118.307480]}/>  */}
          <Marker position={{ lat: pickLocation[0], lng: pickLocation[1] }} />
          <Marker position={{ lat: dropLocation[0], lng: dropLocation[1] }} />
        </MapContainer>

        {/* <img src={map} alt="map" className='w-full  object-contain'/> */}
        <section className="backdrop-blur-sm bg-white/10 rounded-lg z-50 p-6 w-full  space-y-4 absolute bottom-0">
          <section className="flex justify-between items-center">
            <section className="flex items-center space-x-4">
              <img
                className="w-10 h-10 rounded-lg object-cover"
                src={driverDetails?.driver_image}
                alt="driverimage"
              />
              <span className="font-semibold font-hel text-black">
                {driverDetails?.driver_name}
              </span>
            </section>
            <section className="flex flex-col items-center space-y-2 ">
              <img src={clock} className="w-6" alt="clock" />

              {/* <p className="text-[#646464] text-xs">
                {new Date(driverDetails?.createdAt).toLocaleString()}
              </p> */}

              <p className="text-[#646464] text-xs">
                {new Date().toLocaleString()}  {/* for current date and time */}
              </p>

            </section>
          </section>

          <section className="flex justify-between items-center">
            <section className="flex flex-col space-y-5">
              <section className="flex space-x-3">
                <img src={call} alt="callIcon" />{" "}
                {/* <span className="text-black font-normal text-sm">
                  {driverDetails?.driver_phone_no}
                </span> */}

                <a href={`tel:${driverDetails?.driver_phone_no}`} className="text-blue-600 font-normal text-sm">
                  {driverDetails?.driver_phone_no}
                </a>

              </section>
              <section className="flex space-x-3">
                <img src={car} alt="car" />{" "}
                <span className="text-black font-normal">
                  {carDetails?.car_brand_name} {`(${carDetails?.car_number})`}
                </span>
                {/* <span className="text-black font-normal">
                  
                </span> */}
              </section>
            </section>

            {/* this is eta section */}

            <section className="flex flex-col items-center space-y-2 ">
              {/* <img src={clock} className="w-6" alt="clock" /> */}
              <p className="text-black font-normal text-sm mr-1">
                ETA: {newEta}
              </p>
              {/* <Status status='offline'/> */}
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default LoactionMap;
