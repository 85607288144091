import React from 'react'
import locationMark from '../../Assets/icons/Vector.svg'
import Input from '../../Components/Input'
import InputBox from '../../HOC/InputBox'
type Props = {
  pick_location : string
  drop_location : string
}

const LocationDetails = ({pick_location, drop_location}: Props) => {
  // console.log("in the location details", pick_location, drop_location)
  return (
    <section  className=''>
        <header className='py-8 bg-[#1F1F1F] '>
           <div className='flex items-center backdrop-blur  justify-center space-x-3'>
            <img src={locationMark} alt="locationMark"/>
           <h1 className='text-2xl text-white'>Ride Details</h1>
           </div>
        </header>
        <section  className='bg-locationbg bg-no-repeat bg-cover px-6 py-5 space-y-5'>
        <InputBox backgroundType="backdrop-blur-inputBlur bg-white/10">
          <Input labelStyle='sfv' value={pick_location} placeholder="Enter your short feedback" label="Pick Location" />
        </InputBox>
        <InputBox backgroundType="backdrop-blur-inputBlur bg-white/10">
          <Input labelStyle="vd" value={drop_location} placeholder="Enter your short feedback" label="Drop Location" />
        </InputBox>
        </section>
    </section>
  )
}

export default LocationDetails