import axios from "./axios";

export const getDriveDetails = (userID: string) => {
  const resp = axios.get(`bookcar/${userID}`);
  return resp;
};

export const getEtaData = (userID: string, hotelID: string) => {
  const resp = axios.get(`pickup/eta/${userID}`, {
    headers: {
      hotel: hotelID,
    },
  });
  return resp;
};

export const getContentManagmentData = () => {
  const resp = axios.get(`contentmanagment`);
  return resp;
};

export const submitFeedback = (bookingID: string, payload: any) => {
  const resp = axios.post(`rating/byguest/${bookingID}`, payload);
  return resp;
};

export const getDriverLocation = (id: string) => {
  const resp = axios.get(`location/${id}`);
  return resp;
};
