import  { useEffect, useState } from "react";
import FilledButton from "../../Components/Button";
import Input from "../../Components/Input";
import Modal from "../../Components/Modal";
import Rating from "../../Components/Rating";
import InputBox from "../../HOC/InputBox";
import { submitFeedback } from "../../Services/getServices";

type Props = {
  driver : any,
  bookingID : string,
  guestName : string
};
// const imgURL =
//   "https://i.pinimg.com/474x/49/71/2c/49712cc56dabe8f4e6563d40079c99dc.jpg";
const DriverFeedback = ({ driver , bookingID, guestName}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedBack] = useState("")
  const [rating, setRating] = useState(5)
  const [tip, setTip] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(modalOpen)
    document.body.style.overflow = 'hidden'
    else 
    document.body.style.overflow = 'auto'
  },[modalOpen])
  

  const changeRating = (rating : number) => {
        setRating(rating)
  }

  const submitDriverFeedback = async () => {
    console.log(driver._id,"i ma here")
    let values = {
      "rating_by_guest": rating,
      "feedback_by_guest": feedback,
      "tip_by_guest": Number(tip),
      "guest_name" : guestName,
      "driver_id" : driver._id
   }
    setLoading(true)
      try {
        
        const resp = await submitFeedback(bookingID, values)
        console.log(resp)
        setFeedBack("")
        setTip("")
        handleModal()
      } catch (error) {
          console.log(error)
      } finally {
        setLoading(false)
      }
  }

  const handleModal = () => {
    setModalOpen(true)
    setTimeout(() => {
      setModalOpen(false)
    },5000)
  }

  return (
    <div className="bg-[#151719] space-y-5 py-11">
      {
        modalOpen && <Modal/>
      }
      <section  className="mb-11">
        <section className="flex flex-col justify-center items-center space-y-4">
          <img src={driver?.driver_image} alt="driverImage" className="w-20 h-20 rounded "></img>
          <h3 className="text-base text-[#FFFFFF]">
            How's your ride with {driver?.driver_name}
          </h3>
          <Rating changeRating={changeRating}  rating={rating}/>
        </section>
      </section>
      <section  className="space-y-6 mt-10 w-11/12 mx-auto">
        <div>
        <InputBox backgroundType="bg-[#1A1D1F]">
          <Input placeholder="Enter your short feedback" label="Feedback" value={feedback} onChange={(e:any) => setFeedBack(e.target.value)}/>
        </InputBox>
        <p className="text-sm mt-2 text-[#8C8C8C]">
          You can edit your feedback until the ride ends.
        </p>
        </div>
        <InputBox backgroundType="bg-[#1A1D1F]">
          <Input label="Tip" type="number" placeholder="100% of the tips go to the driver" value={tip} onChange={(e:any) => setTip(e.target.value)}/>
        </InputBox>
        <FilledButton loading={loading} handleClick={submitDriverFeedback} title="Done" filled={false}>
            Done
        </FilledButton>
      </section>
    </div>
  );
};

export default DriverFeedback;
