import modalCar from '../Assets/icons/modalCar.svg'

type Props = {};

const Modal = (props: Props) => {
  return (
 
    <div className="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    
      <div className="fixed inset-0 bg-[#414141] bg-opacity-100 transition-opacity"></div>
    
      <div className="fixed z-50 inset-0 overflow-y-auto max-w-xs py-10 mx-auto ">
        <div className="flex  items-center justify-center min-h-full p-4 text-center sm:p-0">
     
          <div className="relative bg-white z-50 overflow-visible rounded-2xl py-6 px-3 text-center shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
         
            <img src={modalCar} className="absolute -top-4 right-0 z-20" alt='modalCar' />
          <h2 className="text-lg font-bold text-[#2A85FF]">
          Above and Beyond
            </h2>
          <p className="text-sm font-normal text-[#141212]">
          Thank you for your feedback! As always we look forward to being of service to you. Hope you enjoyed the Four Seasons experience.
          </p>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Modal;
