import { useEffect } from "react";
import { useMap } from "react-leaflet";
import car from "../Assets/icons/startPoint.svg";
import destination from "../Assets/icons/destination.svg";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "lrm-google";
declare let L: any;
require("lrm-google");
interface IRouting {
  pickLat: any;
  pickLng: any;
  dropLat: any;
  dropLng: any;
  driverLocation?: any;
  rideStatus: string;
  driverLocationLat: number;
  driverLocationLong: number;
  // setEta: React.Dispatch<React.SetStateAction<string>>;
}

const Routing = ({
  pickLat,
  pickLng,
  dropLat,
  dropLng,
  rideStatus,
  driverLocationLat,
  driverLocationLong
}: // setEta,
  IRouting) => {
  console.log(
    pickLat,
    pickLng,
    dropLat,
    dropLng,
    driverLocationLat,
    driverLocationLong,
    "me checking"
  );
  const map = useMap();
  const key = "8ERQNiyWSApj54Ifwo8PgQ";
  // const secret_key = "edbe8s0sLNCBPLYJQJV-ekJ6ouvUIUgwDXqLLYr2CDmZto5sROpviKYTxiFaGK52M2-E4fs_pTLPynyKffeYAQ"
  // useEffect((): any => {
  //   if (!map) return;

  //   // const Timeconverter = (num: number) => {
  //   //   if (num < 60) {
  //   //     return num + " Minutes";
  //   //   } else {
  //   //     let hours = num / 60;
  //   //     let rhours = Math.floor(hours);
  //   //     let minutes = (hours - rhours) * 60;
  //   //     let rminutes = Math.round(minutes);

  //   //     let timeInstring = "";

  //   //     if (rhours > 1) {
  //   //       timeInstring = timeInstring + rhours + " Hours ";
  //   //     } else if (rhours <= 1) {
  //   //       timeInstring = timeInstring + rhours + " Hour ";
  //   //     }
  //   //     if (rminutes > 1) {
  //   //       timeInstring = timeInstring + rminutes + " Minutes ";
  //   //     } else if (rminutes <= 1) {
  //   //       timeInstring = timeInstring + rminutes + " Minute ";
  //   //     }
  //   //     return timeInstring;
  //   //   }
  //   // };

  //   function waypointscondition() {
  //     console.log(rideStatus, "this is ride status");
  //     // console.log(driverLocation, "this is driver location");
  //     console.log(pickLat, pickLng, dropLat, dropLng, "this is pick and drop");

  //     if (rideStatus === "pending") {
  //       return {
  //         waypoints: [
  //           L.latLng(driverLocationLat, driverLocationLong),
  //           L.latLng(pickLat, pickLng),
  //         ],
  //       };
  //     } else if (rideStatus === "accepted") {
  //       return {
  //         waypoints: [
  //           L.latLng(driverLocationLat, driverLocationLong),
  //           L.latLng(dropLat, dropLng),
  //         ],
  //       };
  //     } else {
  //       return {
  //         waypoints: [
  //           L.latLng(driverLocationLat, driverLocationLong),
  //           L.latLng(dropLat, dropLng),
  //         ],
  //       };
  //     }
  //   }

  //   console.log(waypointscondition().waypoints, "checking my waypoints");
  //   const routingControl = L.Routing.control({
  //     waypoints: waypointscondition().waypoints,
  //     // waypoints:aaa,
  //     // router : L.Routing.google("c"),
  //     routeWhileDragging: false,
  //     key: key,
  //     show: false,
  //     Routing: true,
  //     DragEvent: false,
  //     PointerEvent: false,
  //     createMarker: function (i: number, waypoint: any, n: number) {
  //       console.log("CREATE MARKER CALLED", waypoint);

  //       function iconcondition() {
  //         if (rideStatus === "pending") {
  //           if (i === 0) {
  //             return car;
  //           } else {
  //             return destination;
  //           }
  //         } else if (rideStatus === "accepted") {
  //           if (i === 0) {
  //             return car;
  //           } else {
  //             return destination;
  //           }
  //         }
  //         else {
  //           return destination;
  //         }
  //       }

  //       const marker = L.marker(waypoint.latLng, {
  //         draggable: false,
  //         bounceOnAdd: false,
  //         icon: L.icon({
  //           iconUrl: iconcondition(),
  //           // iconSize: [38, 95],
  //           iconAnchor: [22, 33],
  //           popupAnchor: [-3, -76],
  //           // shadowUrl: './assets/global/img/marker-shadow.png',
  //           // shadowSize: [68, 95],
  //           // shadowAnchor: [22, 94]
  //         }),
  //       });
  //       return marker;
  //     },
  //   })
  //     .on("routesfound", (e: any) => {
  //       let summary = e.routes[0].summary;
  //       console.log(summary, "checking minutes");
  //       // console.log(Math.round((summary.totalTime % 3600) / 60) + " minutes");
  //       // let eta = Math.round(summary.totalTime / 60);
  //       // let etaTostring = Timeconverter(eta);
  //       // setEta(etaTostring);
  //     })
  //     .addTo(map);

  //   return () => map.removeControl(routingControl);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [map, driverLocationLat, driverLocationLong]); // added driver coordinates to re-render if the coordinates changed
  

  useEffect(():any => {
    if (!map) return;
  

    function waypointscondition() {
      console.log(rideStatus, "this is ride status");
      // console.log(driverLocation, "this is driver location");
      console.log(pickLat, pickLng, dropLat, dropLng, "this is pick and drop");

      if (rideStatus === "pending") {
        return {
          waypoints: [
            L.latLng(driverLocationLat, driverLocationLong),
            L.latLng(pickLat, pickLng),
          ],
        };
      } else if (rideStatus === "accepted") {
        return {
          waypoints: [
            L.latLng(driverLocationLat, driverLocationLong),
            L.latLng(dropLat, dropLng),
          ],
        };
      } else {
        return {
          waypoints: [
            L.latLng(driverLocationLat, driverLocationLong),
            L.latLng(dropLat, dropLng),
          ],
        };
      }
    }

    const routingControl = L.Routing.control({
      waypoints: waypointscondition().waypoints,

      routeWhileDragging: false,
      key: key,
      show: false,
      Routing: true,
      DragEvent: false,
      PointerEvent: false,
      createMarker: function (i: number, waypoint: any, n: number) {
        console.log("CREATE MARKER CALLED", waypoint);

        function iconcondition() {
          if (rideStatus === "pending") {
            if (i === 0) {
              return car;
            } else {
              return destination;
            }
          } else if (rideStatus === "accepted") {
            if (i === 0) {
              return car;
            } else {
              return destination;
            }
          }
          else {
            return destination;
          }
        }

        const marker = L.marker(waypoint.latLng, {
          draggable: false,
          bounceOnAdd: false,
          icon: L.icon({
            iconUrl: iconcondition(),
            // iconSize: [38, 95],
            iconAnchor: [22, 33],
            popupAnchor: [-3, -76],
            // shadowUrl: './assets/global/img/marker-shadow.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
          }),
        });
        return marker;
      },
    })
      .on("routesfound", (e: any) => {
        let summary = e.routes[0].summary;
        console.log(summary, "checking minutes");
  
        // Center the map on the car's position when routes are found
        map.setView([driverLocationLat, driverLocationLong], map.getZoom(), {
            animate: true,
            duration: 1.5, // Adjust the duration based on your preference
            easeLinearity: 0.25, // Adjust the easeLinearity for smoother animation
          });
      })
      .addTo(map);
  
    return () => map.removeControl(routingControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, driverLocationLat, driverLocationLong]);
  
  
  return null;
};

export default Routing;