import axios from 'axios'

const baseURL = `https://api2.titanurban.com/api/v1/`;

const axiosInstance = axios.create({
    // .. congigure axios baseURL
    baseURL: `${baseURL}`
  });
  


export default axiosInstance