import React, { ReactNode } from 'react'

type Props = {
    children : ReactNode
    backgroundType : string
}

const InputBox = ({children , backgroundType}: Props) => {
  return (
    <div className={`px-3 py-5 ${backgroundType}`}>
        {
            children
        }
    </div>
  )
}

export default InputBox