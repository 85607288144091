import { useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import Cover from "./Screens/CoverPage/Cover";
import DriverFeedback from "./Screens/CoverPage/DriverFeedback";
import FooterAd from "./Screens/CoverPage/FooterAd";
import LocationDetails from "./Screens/CoverPage/LocationDetails";
import LoactionMap from "./Screens/CoverPage/LoactionMap";
// import Modal from "./Components/Modal";
import { getDriveDetails } from "./Services/getServices";
import { Loader } from "./Components/Loader";

function App() {
  const [driveDetails, setDriveDetails] = useState<any>([]);
  // const [driverLocation, setDriverLocation] = useState<number[]>();
  const [loading, setLoading] = useState(true);
  // const id =
  //   window.location.href.split('/').at(-1) || `62d521c28f59d6f43458fc96`
  const id =
    window.location.href.split("/").at(-1) || `638895e68b4e0a0ab5bf0a85`;

  useEffect(() => {
    getAllGuestData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const getAllGuestData = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await getDriveDetails(id);
      setDriveDetails(data.data);
      console.log(data, "this is driver detail");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App  bg-[#151719]">
      <main className="max-w-sm mx-auto ">
        <Cover />
        {loading ? (
          <div className="h-52">
            <Loader />
          </div>
        ) : (
          <>
            <LoactionMap
              pickLocation={driveDetails?.pick_location?.coordinates}
              dropLocation={driveDetails?.drop_location?.coordinates}
              driverDetails={driveDetails?.driver}
              carDetails={driveDetails?.car}
            />
            <LocationDetails
              pick_location={driveDetails?.formatted_pick_address}
              drop_location={driveDetails?.formatted_drop_address}
            />
            <DriverFeedback driver={driveDetails?.driver} bookingID={id} guestName={driveDetails.guestName}/>
          </>
        )}
        <FooterAd />
      </main>
    </div>
  );
}

export default App;
