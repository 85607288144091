// import { TiWarningOutline } from 'react-icons/ti'

type Props = {
  placeholder?: string;
  label?: string;
  error?: string;
  value?: string | number;
  onChange?: any;
  type?: string;
  large?: Boolean;
  name ?: string
  touched ?: boolean;
  errorIcon ?: React.ReactNode
  labelStyle ?: string
};

const Input = ({...props}: Props) => {
  const errorStyles = props.error && props.touched ? "text-red-400" : "text-white";
  const inputStyles = `border-0 
  border-b
  ${props.labelStyle ? "text-white" : "text-primary-blue"}
  focus:border-white
  border-[#969696]
  bg-transparent
  ${errorStyles}
  w-full
  ${props.large ? "" : "text-lg"}
  outline-none py-2
 placeholder:text-gray-600`
  return (
    <div className="flex flex-col items-start w-full relative">
      <label className={`mb-0 ${props.labelStyle ? "text-primary-blue" : "text-white" } text-base`}>{props.label}</label>
      <input
      autoComplete={'off'}
        name={props.name}
        className={inputStyles}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
      />
      {
          props.error && props.touched && props.errorIcon
      }
    </div>
  );
};

export default Input;
