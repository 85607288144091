import React from "react";
import Logo from "../../Assets/images/logo.png";
type Props = {};

const Cover = (props: Props) => {
  return (
    <main className="w-full items-center  h-screen flex flex-col justify-center relative">
        <div className="absolute inset-0 h-screen
        z-10
          bg-black bg-opacity-25 bg-no-repeat bg-blend-normal
          w-full bg-cover bg-mainBackground">

        </div>
        <div className="absolute inset-0 h-screen
         backdrop-blur-xs bg-black/40
          w-full bg-cover z-20">

        </div>
      <section className="w-full z-30  h-1/2  text-center relative justify-center  flex-1 items-center  
      flex flex-col ">
      <section className="mb-6 ">
        <img
          className=" w-48 h-32 z-30 "
          src={Logo}
          alt="logo"
        />
      </section>
        <h1 className="text-white font-normal absolute bottom-0 text-3xl tracking-widest ">
          'EXPERIENCE <span className="whitespace-nowrap">FOUR SEASONS</span>'
        </h1>
      </section>
      <section className="flex-1">

      </section>
      
    </main>
  );
};

export default Cover;
