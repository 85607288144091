import { memo, useEffect, useState } from 'react'
import adImage from '../../Assets/images/adv.png'
import { Loader } from '../../Components/Loader'
import { getContentManagmentData } from '../../Services/getServices'
type Props = {
  bookingID ?: string
}

const FooterAd = ({bookingID}: Props) => {
  const [loading, setLoading] = useState(true)
  const [contMng, setContMgn] = useState<any>()
  useEffect(() => {
    getContentManagementAD()
  },[])

  const getContentManagementAD = async () => {
    setLoading(true);
    try {
      const { data } = await getContentManagmentData();
      setContMgn(data.data[data.data.length-1]);
      console.log(data.data[data.data.length-1],"content mangement");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <footer  className='aspect-square bg-gray-600 relative flex justify-center items-end'>
       {
        loading ? <Loader/> : <>
        <img src={contMng?.image || adImage} alt="adimage" className='w-full h-full object-cover'/>
        <section  className='absolute left-0 text-left z-10 bottom-10 backdrop-blur-xs px-3 py-10 bg-black/20 mr-9'> 
            <h1 className='text-white text-lg tracking-widest'>
            {
              contMng?.heading
            }
            </h1>
            <h3 className='text-white text-base tracking-widest'>
            {
              contMng?.sub_heading
            }
            </h3>
        </section></>
       }
        
    </footer>
  )
}

export default memo(FooterAd)